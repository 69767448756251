import * as globals from "./globals.js";
import Pusher from "pusher-js";

/**
 * TODO: Take a look at Laravel Echo - we should not need to expose pusher directly.
 */

/**
 * Log to the console if debug is enabled.
 */
Pusher.logToConsole = globals.debug();

/**
 * Get the project's pusher key, which might not exist.
 */
const key = globals.getPusherKey();

/**
 * Get the project's pusher cluster option.
 */
const cluster = globals.getPusherCluster();

/**
 * Warn the developer that a key was not found (it is not always required)
 */
if (!key) {
    console.error("Pusher key not found!");
}

/**
 * Warn the developer that a cluster option was not found (it is not always required)
 */
if (!cluster) {
    console.error("Pusher cluster option not found!");
}

/**
 * Create a new pusher instance if a key is set. pusher.connect().then(instance).
 *
 * Does not reject.
 *
 * @returns Promise
 */
export function connect() {
    const config = {
        cluster: cluster,
        encrypted: true
    };

    return new Promise((resolve, reject) => {
        if (key) {
            return resolve(new Pusher(key, config));
        }
    });
}
